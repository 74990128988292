import { Resident, ResidentPreferenceDataRaw } from '@resparke/models';
import { FavouritesCollection } from './global';


export interface ResidentContent {
  id: string;
  headerTitle: string;
  headerIcon: string;
  headerBgColor: string;
  rows: ResidentContentRow[];
  memory?: string;
  edit?: boolean;
  slug?: string;
}

export interface ResidentContentRow {
  thumbnail?: string;
  heading: string;
  title: string;
  type: string;
  link: string;
}


export interface SuggestedContentCollection {
  [key: string]: ResidentContent;
}

export const residentFamilyFields = `
  id
  firstName
  lastName
  relationship
  confirmed
  email
  _auditLog
`;

export const residentFields = `
  id
  facilityId
  preferenceId
  data {
    firstName
    lastName
    preferredName
    approved
    dateCreated
    dateApproved
    approver
  }
  firstName
  lastName
  preferredName
  roomNumber
  dateCreated
  deleted
  program
  locked
  familyStatus {
    status
    dateUpdated
  }
`;

export const preferenceFields = `
  id
  profile {
    approved
    dateCreated
    gender
    dob
  }
  music {
    approved
    dateCreated
    genres
    favouriteArtists
    suggestedArtists
    memories
  }
  occupationsLife {
    approved
    dateCreated
    industries
    suggestedOccupations
    memories
    achievements
  }
  languageCountry {
    approved
    dateCreated
    primaryLanguage
    secondaryLanguage
    countryOfBirth
    countryGrewUp
    countryOtherPlaces
  }
  cultureValuesBelieves {
    approved
    dateCreated
    religion
    suggestedReligion
    practiceReligion
    culturalBackground
    personalIdentity
    communityGroups
  }
  peoplePets {
    approved
    dateCreated
    pets
    animals
    petsMemories
    importantPeople {
      name
      relationship
    }
  }
  hobbiesInterestsSports {
    approved
    dateCreated
    hobbiesInterests
    hobbiesMemories
    sports
    suggestedSports
    sportsMemories
  }
  conversationStarters {
    approved
    dateCreated
    conversationStarters
  }
  notes {
    approved
    dateCreated
    notes
  }
`;

export interface ResidentSubscriptionOutput {
  preference: ResidentPreferenceDataRaw;
  resident: Resident,
}



export enum ResidentGender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
}

export interface ResidentFavourites {
  id: string;
  dateCreated: number;
  favourites?: FavouritesCollection;
}

type ResidentContentSuggestion = {
  residentId: String;
  residentName: String;
  facilityName: String;
  type: String;
  suggestions: [String];
}